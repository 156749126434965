import { htmlE } from "/WebInterface/new-ui/assets/js/utils/te.js";

const AutocompleteTemplate = htmlE`
  ${({ id }) => {
    return `<div${id ? ` id=${id}` : ""} class="autocomplete-wrapper">
      <select multiple class="is-hidden"></select>
      <div class="autocomplete-input-container">
        <div class="autocomplete-input input">
          <div class="is-flex-grow-1">
            <input type="text" />
          </div>
        </div>
        <div class="autocomplete-input-options bg-primaryMain is-hidden"></div>
      </div>
    </div>`;
  }}

`;

export default AutocompleteTemplate;
